
import { Vue, Component, Prop } from 'vue-property-decorator'
import Validator from 'async-validator'
import { FileTransferHandler } from '@/lib/FileTransferHandler'
import { IAnalogueRemittanceRow } from '@/presentation/remittance/model/IAnalogueRemittanceRow'
import { staticValues } from '@/static/StaticValues'
import ManageTableOptions from '@/models/forms/ManageTableOptions'
import { numToKorean } from 'num-to-korean';

@Component
export default class ManageTable extends Vue {
  @Prop(Array) readonly tableData!: Array<any>
  @Prop(Object) readonly tableOption!: Dictionary<ManageTableOptions>
  @Prop(Object) readonly descriptor!: Dictionary<any>
  @Prop(Boolean) readonly isManualApply!: Boolean
  @Prop(Boolean) readonly isAnalogueApply!: Boolean
  @Prop(Boolean) readonly hasValidateFailAction!: Boolean
  @Prop({ required: false, type: String }) readonly countryWithMethod!: string
  @Prop({ required: false, type: String }) readonly fixed!: string
  @Prop({ required: false, type: String, default: '' }) readonly parentNode!: string

  tableHead: Array<string> = []
  validationDescriptor: Dictionary<any> = {}
  validator!: Validator
  errorMessageList: Dictionary<any> = {}
  updatableRows: Array<boolean> = []
  defaultAcceptableExtensions: Array<string> = [
    '.png', '.jpg', '.jpeg', '.gif', '.tiff', '.bmp', '.pdf'
  ]

  get getDefaultAcceptableExtensions (): string {
    return this.defaultAcceptableExtensions.join(',')
  }

  getSelectOptions (option: string | Array<any>, row?: any) {
    const options = staticValues as Dictionary<any>
    if (typeof option === 'string' && options.hasOwnProperty(option)) {
      const country = this.countryWithMethod.includes('_')
        ? this.countryWithMethod.split('_')[0]
        : row.country
      if (Array.isArray(options[option])) return options[option]
      return options[option][country] ? options[option][country] : options[option]['ZZ']
    }
    return option
  }

  isUpdatableFormType (prop: string, type: string, index: number) {
    return this.tableOption[prop]?.type === type && this.updatableRows[index]
  }

  isFormType (prop: string, type: string) {
    return this.tableOption[prop]?.type === type
  }

  commaAmount (scopeRow: string) {
    const commaNumber: number = Number(scopeRow)
    return commaNumber.toLocaleString()
  }

  isUpdatableRow (index: number) {
    return this.updatableRows[index]
  }

  isRequiredField (row: IAnalogueRemittanceRow, prop: string): boolean {
    if (!this.isAnalogueApply) return true
    if (row.analogue) return true
    const hidePropsInAnalogue = ['swift', 'line1', 'memo_to_recipient']
    return !hidePropsInAnalogue.includes(prop)
  }

  isRequiredValidation (row: IAnalogueRemittanceRow): boolean {
    if (!this.isAnalogueApply) return true
    return row.analogue
  }

  isHideColumn (prop: string) {
    return this.tableOption[prop]?.type === 'hide' || prop.includes('_class') || prop.includes('_message')
  }

  getLabel (prop: string) {
    return `${this.$t(`sheet.field.${prop}`)}`.includes('sheet.field') ? prop : this.$t(`sheet.field.${prop}`)
  }

  getFormItemClass (row: any, prop: string) {
    const propClass = row[`${prop}_class`] ? ` ${row[`${prop}_class`]}` : ''
    const buttonClass = this.tableOption[prop].isButton ? ' is-button' : ''
    return `el-form-item${propClass}${buttonClass}`
  }

  getUpdatableButtonLabel (index: number) {
    const updateComplete = this.$route.path.includes('manualApply') ? `${this.$t('commons.complete')}` : `${this.$t('commons.complete_modify')}`
    return this.updatableRows[index] ? updateComplete : `${this.$t('commons.do_modify')}`
  }

  getUpdatableButtonClass (index: number) {
    return this.isUpdatableRow(index) ? 'is-plain' : 'is-plain button-expand'
  }

  getTableOption (prop: string) {
    return this.tableOption[prop] || {}
  }

  async setValue (row: any, propName: string, value: any, index: number) {
    if(propName === 'amount') {
      row[propName] = value.replaceAll(',','')
    }else {
      row[propName] = this.tableOption[propName].upperCase ? value.toString().toUpperCase() : value
    }
    await this.checkValidation(index, row, propName)
  }

  async fileUploader (row: any, files: any) {
    try {
      const file = files.raw
      const fileName: string = files.name
      const fileExtension = `.${fileName.split('.').pop()}`.toLowerCase()
      const isAcceptableExtension = fileName.includes('.') && this.defaultAcceptableExtensions.includes(fileExtension)
      if (!isAcceptableExtension) return this.$alert(this.$t('commons.upload_file_explanation') as string)
      const fileTransferHandler = FileTransferHandler.getInstance()
      row.value = await fileTransferHandler.blobToBase64(file)
      row.name = files.name
    } catch (error) {
      this.$message({ message: error.message, type: 'error' })
    }
  }

  setTableHead () {
    if (!this.tableData?.length) return []
    const keys = this.tableData.reduce((prev: Array<any>, curr: any) => {
      return prev.concat(Object.keys(curr))
    }, [])
    const heads = Array.from(new Set(keys))
    const filteredHideHeads = heads.filter(head => {
      const option = this.tableOption[head]
      return option?.type !== 'hide' && head !== 'updatable' && !head.includes('_class') && !head.includes('_message')
    })
    this.tableHead = filteredHideHeads
  }

  onInputClick (scope: any, prop: string) {
    this.$emit(`${this.tableOption[prop].emitHandlerName}`, scope, this.countryWithMethod)
  }

  filterValidationDescriptor () {
    const descriptorProps = Object.keys(this.descriptor)
    descriptorProps.forEach(prop => {
      if (this.tableHead.includes(prop)) {
        this.validationDescriptor[prop] = this.descriptor[prop]
      }
    })
  }

  setValidator () {
    this.validator = new Validator(this.validationDescriptor)
  }

  setUpdatableRow () {
    this.updatableRows = this.tableData.map(row => {
      return row.updatable || false
    })
  }

  addSameRecipientRow (index: number) {
    this.$emit('addSameRecipientRow', this.countryWithMethod, index)
    this.updatableRows.push(true)
  }

  deleteRow (index: number) {
    this.$confirm(`${this.$t('notification.delete_confirm')}`, {
      confirmButtonText: `${this.$t('commons.do_delete')}`,
      confirmButtonClass: 'is-plain',
      cancelButtonText: `${this.$t('commons.cancel')}`
    }).then(() => {
      if (!this.parentNode.includes('invalid')) this.updatableRows.splice(index, 1)
      this.$emit('deleteRow', this.countryWithMethod, index)
    }).catch(() => {
    })
  }

  async toggleUpdatable (updatable: boolean, index: number, row: any) {
    const toggleUpdateRow = async (isNotValidated?: boolean) => {
      if (isNotValidated) {
        await this.checkValidation(index, row)
        return
      }
      this.updatableRows[index] = !this.updatableRows[index]
      this.updatableRows = Array(...this.updatableRows)
    }
    if (!updatable) {
      this.$emit('toggleUpdatable', this.countryWithMethod, index, toggleUpdateRow)
      return
    }
    if (!this.isRequiredValidation(row)) {
      this.$emit('toggleUpdatable', this.countryWithMethod, index, toggleUpdateRow)
      return
    }
    if (await this.checkValidation(index, row)) {
      this.$emit('toggleUpdatable', this.countryWithMethod, index, toggleUpdateRow)
      return
    }
    if (this.hasValidateFailAction) this.$emit('failValidate')
  }

  async checkValidation (index: number, row: any, propName?: string) {
    const updateRow = this.tableData[index]
    return this.validator.validate(updateRow).then(() => {
      row[`${propName}_class`] = ''
      this.errorMessageList[`${index}_${propName}`] = ''
      this.errorMessageList = JSON.parse(JSON.stringify(this.errorMessageList))
      return true
    }).catch(({ fields }) => {
      Object.keys(fields).forEach(prop => {
        row[`${prop}_class`] = 'is-error'
        this.errorMessageList[`${index}_${prop}`] = fields[prop][0].message
      })
      if (!fields.hasOwnProperty(propName)) {
        row[`${propName}_class`] = ''
        this.errorMessageList[`${index}_${propName}`] = ''
      }
      this.errorMessageList = JSON.parse(JSON.stringify(this.errorMessageList))
      return false
    })
  }

  getValue(value: string, propName?: string): string {
    if(propName === 'amount') return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return value;
  };

  numberToHangul(number: number):string {
    const convertedValue = numToKorean(number, 'spacing')
    if(convertedValue.includes('undefined')) return '허용 범위를 초과하였습니다.';
    return `${convertedValue}`;
  }  

  removeCommaAndConvertToNumber(value: string): number {
    const removeCommaStringValue = value.replaceAll(',','');
    const floor = Math.floor(Number(removeCommaStringValue))
    return floor
  }

  created () {
    this.setTableHead()
    this.filterValidationDescriptor()
    this.setValidator()
    this.setUpdatableRow()
  }
}
